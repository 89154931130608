// UI-kit
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @import "./ui-kit/fonts";
  @import "./ui-kit/variables";
  @import "./ui-kit/reset";
  @import "./ui-kit/scrollbar";
}

@layer components {
  @import "./ui-kit/listing";
  @import "./ui-kit/load";
  @import "./ui-kit/icon";
  @import "./ui-kit/link";
}

@import "./ui-kit/custom";
@import "./ui-kit/waved";

// Frontend
@import "./frontend/main";