// Мобильное меню
.mobile-menu {
  &__content {
    box-shadow: 0 0 0 100vw theme("colors.black.DEFAULT / 50%");
  }

  &[data-sidebar="close"] {
    opacity   : 0;
    visibility: hidden;
  }

  &[data-sidebar="close"] &__content {
    transform : translateX(-100%);
    opacity   : 0;
    visibility: hidden;
  }

  &[data-sidebar="close"] &__head {
    transform: translateY(-80px);
  }

  &[data-sidebar="close"] &__nav {
    transform: translateX(-220px);
    opacity  : 0;
  }

  &[data-sidebar="close"] &__footer {
    transform: translateY(-50px);
    opacity  : 0;
  }

  &[data-sidebar="open"] {
    opacity   : 1;
    visibility: visible;
  }

  &[data-sidebar="open"] &__content {
    transform : translateX(0);
    opacity   : 1;
    visibility: visible;
  }

  &[data-sidebar="open"] &__head {
    transform: translateY(0);
  }

  &[data-sidebar="open"] &__nav {
    transform: translateX(0);
    opacity  : 1;
  }

  &[data-sidebar="open"] &__footer {
    transform: translateY(0);
    opacity  : 1;
  }
}

// Главный блок
.main {
  &__elem {
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.1);
  }

  &__item {
    &--blue {
      background-image: radial-gradient(100% 100% at 50% 0%, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 73.05%), radial-gradient(59.82% 533.8% at 30.65% 40.18%, rgba(90, 154, 249, 0.7) 0%, rgba(90, 154, 249, 0) 100%);
    }

    &--orange {
      background-image: radial-gradient(100% 100% at 50% 0%, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 73.05%);
    }
  }

  @screen lg {
    &__elem {
      transition-property: transform;
      transform          : rotateY(calc(var(--x) * -50deg)) rotateX(calc(var(--y) * -30deg));
      box-shadow         : calc(var(--x) * -20px) calc(var(--y) * -5px) 30px rgba(0, 0, 0, 0.2);
    }
  }
}

// Расчет
.calculation {
  background: linear-gradient(0deg, rgba(235, 171, 218, 0.2) 0%, rgba(235, 171, 218, 0) 65.21%), linear-gradient(101.47deg, #AECDED 0%, #EAF1F8 100%);

  &__image {
    background-image: radial-gradient(100% 100% at 50% 0%, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 73.05%);
    box-shadow      : 0px 10px 30px rgba(102, 158, 244, 0.7);
  }

  &__content {
    background: radial-gradient(98.48% 326.98% at 0% 3.35%, #FFFFFF 0%, rgba(255, 255, 255, 0.5) 100%);
  }
}

// Проект
.project {
  &-slider {

    &::before,
    &::after {
      content       : "";
      display       : none;
      position      : absolute;
      right         : 0;
      left          : 0;
      width         : 100%;
      height        : 150px;
      pointer-events: none;
      z-index       : 10;
    }

    &::before {
      top       : -80px;
      background: linear-gradient(0, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
    }

    &::after {
      bottom    : -80px;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
    }
  }

  &__warning {
    animation: pulse 3s ease infinite both;
  }

  &__content {
    background: linear-gradient(58.13deg, rgba(235, 171, 218, 0.5) 0%, rgba(148, 134, 206, 0) 79.07%), linear-gradient(241.78deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0) 51.81%), #02274D;
  }

  @screen lg {
    &-slider {

      &::before,
      &::after {
        display: block;
      }
    }
  }
}

@keyframes pulse {

  0%,
  100% {
    transform: scale(1);
  }

  20% {
    transform: scale(1);
  }

  30% {
    transform: scale(1.1);
  }

  40% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  60% {
    transform: scale(1);
  }
}

// Строительство
.construction {
  &__accordion {
    &[data-accordion="active"] .construction__arrow {
      transform: rotate(-90deg);
    }
  }

  &__form {
    box-shadow: 0 0 0 100vw theme("colors.black.DEFAULT / 50%");
    transform : translateX(-375px);

    @screen lg {
      box-shadow: none;
      transform : translateX(0);
    }
  }

  &__sidebar[data-sidebar="open"] {
    @apply visible;
  }

  &__sidebar[data-sidebar="open"] &__form {
    transform: translateX(0);

    @apply visible opacity-100;
  }

  &__card.filter-show {
    animation: filtering-show 0.3s ease both 1;
  }
}

@keyframes filtering-show {
  0% {
    opacity  : 0;
    transform: translateY(50px);
  }

  100% {
    opacity  : 1;
    transform: translateY(0);
  }
}

// Обратная связь
.feedback {
  background: linear-gradient(64.82deg, rgba(235, 171, 218, 0.7) 0%, rgba(148, 134, 206, 0) 57.73%), linear-gradient(241.78deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0) 51.81%), #02274D;

  &__content {
    background: linear-gradient(64.82deg, rgba(235, 171, 218, 0.4) 0%, rgba(148, 134, 206, 0) 57.73%), linear-gradient(241.78deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0) 51.81%), #023162;
  }
}

// Услуги
.services {
  &__info {
    background-image: linear-gradient(180deg, rgba(0, 27, 55, 0) 31.95%, #001B37 100%);
  }

  @screen sm {
    &-wrapper {
      transform: none !important;
    }
  }
}

// Специалист
.specialist {
  &__content {
    background: radial-gradient(100% 100% at 50% 0%, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 73.05%), #0085EA;
  }

  &__circle {
    background: linear-gradient(191.35deg, #AECDED 21.52%, #EAF1F8 82.49%);
    animation : specialist-circle 5s linear infinite;
  }
}

@keyframes specialist-circle {
  0% {
    transform: rotate(0);
  }

  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

// О нас
.about {
  &__content {
    background: radial-gradient(336.9% 105.08% at 0% 100%, rgba(255, 255, 255, 0.592) 0%, rgba(255, 255, 255, 0.232) 100%);
  }

  &__block {
    background: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0) 100%), #CCE6FA;
  }
}

// Видео
.videos {
  &-button {
    &.swiper-button-disabled {
      opacity   : 0;
      visibility: hidden;
    }
  }
}

// Вопросы
.questions {
  &__accordion[data-accordion="active"] &__arrow {
    transform: rotate(-90deg);
  }
}

// Специалисты
.specialists {
  background-image: linear-gradient(0deg, rgba(235, 171, 218, 0.2) 0%, rgba(235, 171, 218, 0) 65.21%), linear-gradient(101.47deg, #AECDED 0%, #EAF1F8 100%);

  &__content {
    background-image: radial-gradient(98.48% 326.98% at 0% 3.35%, rgba(255, 255, 255, 0.63) 0%, rgba(255, 255, 255, 0.42) 100%);
  }
}

// Звонок
.call {
  &__pack {
    background: linear-gradient(0deg, rgba(235, 171, 218, 0.2) 0%, rgba(235, 171, 218, 0) 65.21%), linear-gradient(101.47deg, #AECDED 0%, #EAF1F8 100%);
  }

  &__bg {
    background: radial-gradient(336.9% 105.08% at 0% 100%, rgba(255, 255, 255, 0.444) 0%, rgba(255, 255, 255, 0.174) 100%);
  }

  &__item {
    animation: call-item 7s ease infinite both;
  }
}

@keyframes call-item {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-30px);
  }
}